
import { Component } from 'vue-property-decorator';
import PwrVue from '@/components/PwrVue';
import E401Img from '@/assets/401.svg';
import PwrBtn from '@/components/Pwr/Buttons/PwrBtn/PwrBtn.vue';

@Component({
  components: { PwrBtn }
})
export default class E401View extends PwrVue {
  get img() {
    return E401Img;
  }
}
